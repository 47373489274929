<script setup lang="ts">
import Button from '~/components/design-system/button.vue';
import FacebookIcon from '../design-system/icons/FacebookIcon.vue';
import TwitterIcon from '../design-system/icons/TwitterIcon.vue';
import InstagramIcon from '../design-system/icons/InstagramIcon.vue';
import { socialLinks } from '~/assets/data/config';

const siteStore = useSiteStore();

const regionSocials = computed(() => socialLinks[siteStore.getRegionCode]);

function facebook() {
  window.open(regionSocials.value.facebook);
}
function instagram() {
  window.open(regionSocials.value.instagram);
}
function twitter() {
  window.open(regionSocials.value.x);
}
</script>

<template>
  <div class="flex gap-2">
    <Button type="secondary" shape="square" @click="instagram()">
      <InstagramIcon class="mx-auto" />
    </Button>
    <Button type="secondary" shape="square" @click="facebook()">
      <FacebookIcon class="mx-auto" />
    </Button>
    <Button type="secondary" shape="square" @click="twitter()">
      <TwitterIcon class="mx-auto" />
    </Button>
  </div>
</template>

<style scoped lang="scss"></style>
