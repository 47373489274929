<script setup>
import { useSiteStore } from '~/stores/site.store';
import useClock from '~/composables/useClock';
import { trackingMethods } from '~~/plugins/analytics.client';
import ThemedImage from '~/components/design-system/themed-image.vue';
import { EighteenPlus } from '~/components/design-system/icons';
const { $enabled } = useNuxtApp();
const settings = useSiteStore();
const clock = useClock();
const config = useRuntimeConfig();
const bankingImageArbitraryValues = [
  { dark: '/banking/visa_dark.png', light: '/banking/visa_light.png' },
  {
    dark: '/banking/master_card_dark.png',
    light: '/banking/master_card_light.png',
  },
  // { dark: '/banking/zapper_dark.png', light: '/banking/zapper_light.png' },
  // { dark: '/banking/ozow_dark.png', light: '/banking/ozow_light.png' },
  {
    dark: '/banking/apple_pay_dark.png',
    light: '/banking/apple_pay_light.png',
  },
];
const router = useRouter();
const navigate = (path) => {
  // send GA tracking method when Footer link clicked
  trackingMethods.footerClicked();

  router.push(path);
};
</script>
<template>
  <div class="bg-layer-2 dark:bg-layer-1 py-3 md:py-12">
    <div class="container mx-auto">
      <div
        class="grid grid-cols-2 md:grid-cols-4 text-base-priority cursor-pointer text-sm md:text-base gap-4 mb-9"
      >
        <div
          v-for="item in settings.getNav({ nav: 'footer' })"
          :key="item.Alias"
          class="footer-nav"
          @click="navigate(`${item.Path}`)"
        >
          {{ $t(item.Alias) }}
        </div>
      </div>
      <div
        v-if="settings.getRegionCode === 'JZA'"
        class="border-y-[1px] border-light-200 dark:border-dark-700 py-6 flex justify-center gap-4"
      >
        <ThemedImage
          v-for="({ light, dark }, index) in bankingImageArbitraryValues"
          :key="index"
          alt-text="banking"
          :light="
            useImagePath(
              `/medialibraries/content.gmgamingsystems.com/jackpotcity${dark}`
            )
          "
          :dark="
            useImagePath(
              `/medialibraries/content.gmgamingsystems.com/jackpotcity${light}`
            )
          "
          image-class="h-4 md:h-8"
        />
      </div>
      <div
        v-if="
          $enabled('website.enableappdownload') ||
          $enabled('website.enablesocialicons')
        "
        class="border-y-[1px] border-light-200 dark:border-dark-700 py-6 flex flex-wrap justify-center gap-2"
      >
        <UserInterfaceAppsDownload
          v-if="$enabled('website.enableappdownload')"
          type="footer"
          class="pt-0"
        />
        <UserInterfaceSocialIcons
          v-if="$enabled('website.enablesocialicons')"
        />
      </div>
      <div
        class="text-sm md:text-base dark:text-white text-center px-2 pt-2 pb-0"
      >
        <div v-html="$t(`license-info.${settings.getRegionCode}`)"></div>

        <div class="flex flex-col md:flex-row my-2 justify-between px-2">
          <div>
            {{ $t('software-version') }}
            <span class="font-bold">v{{ config.public.version }}</span>
          </div>
          &nbsp;
          <div>
            {{ $t('current-time') }}
            <span class="font-bold">{{ clock }}</span>
          </div>
        </div>
        <div class="flex-center">
          <a
            v-if="settings.getRegionCode === 'JZA'"
            href="https://cms1.jpc.africa/medialibraries/Jackpotcity.co.za/document/Jackpot_City_Licence_Certificate_with_Sub_URL.pdf"
            target="_blank"
          >
            <LazyDesignSystemLazyImage
              id="mer"
              class="w-20"
              :src="
                useImagePath(
                  '/medialibraries/content.gmgamingsystems.com/jackpotcity/branding/mpumalanga-economic-regulator.png'
                )
              "
              :style="`filter: invert(${
                useColorMode().preference === 'dark' ? 1 : 0
              })`"
              alt="Mpumalanga Economic Regulator"
            />
          </a>
          <div class="bg-base dark:bg-layer-2 rounded-lg ml-2 p-2">
            <EighteenPlus />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.footer-nav {
  font-family: Inter;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
}
@media screen and (max-width: 1024px) {
  #wes-government {
    width: 50%;
  }

  #for-banking {
    display: grid !important;
    text-align: center;
  }
}

@media screen and (min-width: 1025px) {
  #wes-government {
    width: 30%;
  }
}
</style>
